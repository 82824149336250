import React from 'react';

function App() {
  return (
    <div>
      <h1>Heyo!  🤘🏻</h1>
    </div>
  );
}

export default App;
